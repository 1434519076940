html,
#root {
  height: 100%;
  overflow: hidden;
}

body.ReactModal__Body--open {
  overflow-y: hidden;
}
.wrapper {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-left: 260px;
  padding: 0 30px;
  transition: margin ease 0.1s;
  @media (max-width: $tablet-width) {
    padding: 0 15px;
    margin-left: 0;
    margin-top: 55px;
    height: calc(100% - 55px);
  }
}
body.--nav-collapsed .wrapper {
  /* this relates to the navigation panel, which will move to the left 100px.  */
  margin-left: 100px;
  transition: margin ease 0.2s;
  @media (max-width: $tablet-width) {
    margin-left: 0; // override for mobile
  }
}
body {
  overflow-anchor: none;
}
body.--loading {
  overflow-y: hidden;
}

.container {
  max-width: 2100px;
  width: 100%;
  margin: 0 auto;
}

main {
  flex-grow: 1;
  overflow: auto;
}
