.ant-picker,
.ant-picker-focused,
.ant-picker:hover,
.ant-picker-focused {
  border: none;
  border-color: white;
  box-shadow: none;
}

.anticon svg {
  width: 22px;
  height: 22px;
}

.ant-picker-input > input {
  border: 1px solid #bcbec0 !important;
  border-radius: 3px !important;
  font-size: 15px;
  height: 50px !important;
  outline: 0;
  padding: 0 16px !important;
  width: 140px !important;
  margin-right: 15px;
}

/*-----------------------------------------------------------------*/
.ant-picker-date-panel {
  width: auto !important;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
  padding: 8px 31px;
}

.ant-picker-header {
  padding: 20px 30px 0px !important;
  height: 55px;
  border: none !important;
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none;
}

.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  display: none !important;
}

.ant-picker-prev-icon,
.ant-picker-next-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27%3E%3Cg class=%27nc-icon-wrapper%27 fill=%27%23000000%27%3E%3Cdefs stroke=%27none%27%3E%3C/defs%3E%3Cpath class=%27cls-1%27 d=%27M12 23.25V.75%27 fill=%27none%27 stroke=%27%23000000%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5px%27%3E%3C/path%3E%3Cpath class=%27cls-2%27 d=%27M22.5 11.25L12 .75 1.5 11.25%27 fill=%27none%27 stroke=%27%23000000%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5px%27 fill-rule=%27evenodd%27%3E%3C/path%3E%3C/g%3E%3C/svg%3E');
  width: 1.5em !important;
  height: 1.5em !important;
  background-size: 100% 100%;
  display: block;
}

.ant-picker-dropdown .ant-picker-cell-disabled::before {
  background: none !important;
}

.ant-picker-prev-icon {
  transform: rotate(-90deg) !important;
}

.ant-picker-next-icon {
  transform: rotate(90deg) !important;
}

.ant-picker-month-btn,
.ant-picker-year-btn {
  color: #40a9ff !important;
  padding: 4px 10px !important;
  border-radius: 3px;
  line-height: 25px !important;
  transition: background-color 0.3s !important;
}

.ant-picker-month-btn:hover,
.ant-picker-year-btn:hover {
  background-color: #f5f5f5 !important;
}

.ant-picker-header button {
  color: rgba(0, 0, 0, 0.5);
}

.ant-picker-header-view button {
  color: #00aeef;
  font-size: 16px;
}

.ant-picker-year-panel .ant-picker-header-super-prev-btn,
.ant-picker-decade-panel .ant-picker-header-super-prev-btn {
  display: inline;
}

.ant-picker-year-panel .ant-picker-header-super-next-btn,
.ant-picker-decade-panel .ant-picker-header-super-next-btn {
  display: inline;
}

.ant-picker-date-panel .ant-picker-body,
.ant-picker-decade-panel .ant-picker-body {
  padding: 30px;
}

.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 30px;
}

.ant-picker-content th {
  font-family: MuseoSlab;
  color: #ccc !important;
  width: 38px;
  margin: 0;
  text-transform: uppercase;
  font-size: 11px;
  border: none;
  text-decoration: underline dotted;
}

/*------------------------------------------------------------*/
.ant-picker-content th,
.ant-picker-content td {
  padding: 0;
}

.ant-picker-cell {
  visibility: hidden;
  padding: 0 !important;
  color: rgba(0, 0, 0, 0);
}

.ant-picker-cell-in-view {
  visibility: visible;
  color: rgba(0, 0, 0, 0.85);
}

.ant-picker-cell .ant-picker-cell-inner {
  min-width: 38px !important;
  height: 38px !important;
  border-radius: 50% !important;
  line-height: 38px !important;
  font-size: 13px;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(
    .ant-picker-cell-range-end
  ):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background: #ccc;
  // color: white;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #00aeef !important;
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none !important;
}

.ant-picker-cell-today:not(.ant-picker-cell-selected) .ant-picker-cell-inner {
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 0.5em;
}

.ant-picker-cell-disabled::before {
  background: white;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(
    .ant-picker-cell-range-end
  )
  .ant-picker-cell-inner {
  border-radius: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(
    .ant-picker-cell-range-start
  )
  .ant-picker-cell-inner {
  border-radius: 50%;
}

.rangepicker-end-label {
  margin-left: 100px !important;
}

.ant-picker-range .ant-picker-active-bar {
  background: #00aeef;
  margin-left: 0;
  margin-top: 2px;
}

.ant-picker-range .ant-picker-clear {
  right: 0;
}

.react-datepicker__day {
  width: 38px;
  height: 38px;
  line-height: 38px;
  margin: 0;
  font-size: 13px;
  border-radius: 50%;
  color: #414042;
}

.react-datepicker__day:hover {
  background: #ccc;
  color: white;
  border-radius: 50%;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background: #edf8fb;
  border-radius: 50%;
  color: #414042;
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background: #00aeef;
  border-radius: 50%;
  color: white;
}

.ant-picker-time-panel {
  width: 240px !important;
}

.ant-picker {
  padding: 0;
  background: inherit;
}

.ant-picker-input > input {
  background: white;
}

.ant-picker-input {
  width: auto;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:hover {
  color: #00aeef;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  height: 38px;
  line-height: 38px;
  -webkit-transition: auto;
  transition: auto;
  padding: 4px 0 4px 14px;
}

.ant-btn-primary {
  background: #00aeef;
  padding: 10px 25px 10px;
  border-radius: 3px;
  border: solid 1px #00aeef;
}

.ant-picker-ranges {
  line-height: 40px;
}

.ant-btn-sm {
  height: 40px;
}

.ant-picker-time-panel-column::after {
  display: inline;
}

.ant-tabs-tab {
  color: #808285;
  font-family: MuseoSlab;
  font-size: 15px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #00aeef !important;
}

.ant-tabs-ink-bar {
  background: #00aeef !important;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.ant-message {
  z-index: 9999999;
}
.ant-message-notice-content {
  padding: 0 !important;
  border-radius: 5px;
  position: relative;
}

.ant-message-error:after {
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  width: 6px;
  background: #ff4d4f;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ant-message-success:after {
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  width: 6px;
  background: #00b259;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}

/* hides default icon */
.ant-message-custom-content .anticon:first-child {
  display: none;
}

/* tooltip   */
.ant-tooltip {
  z-index: 99999;
}

.ant-tooltip-arrow {
  width: 40px;
  height: 40px;
  --antd-arrow-background-color: #414042 !important;
}

.ant-tooltip-arrow-content:before {
  background: #414042 !important;
}

.ant-tooltip-inner {
  background-color: #414042 !important;
  font-size: 15px;
  padding: 7px 9.5px;
  min-height: initial;
}

/* username dropdown overrides */
ul.ant-dropdown-menu {
  box-shadow:
    0 -2px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
}

/* checkbox overrides  */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00aeef;
  border-color: #00aeef;
  border-radius: 4px;
}

/* // checkbox override for disabled. */
.ant-checkbox-disabled + span {
  color: #414042;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  border: 1px solid #d9d9d9;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: white;
}

.ant-checkbox-wrapper.ant-checkbox-group-item {
  margin-left: 0 !important;
}

.ant-menu.ant-menu-vertical {
  border: none !important;
}

// customize radio
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #00aeef;
  background-color: white;
}

.ant-radio-wrapper .ant-radio-inner::after {
  background-color: #00aeef;
  width: 26px;
  height: 26px;
  margin-block-start: -13px;
  margin-inline-start: -13px;
}

.ant-modal-root .ant-modal-wrap {
  z-index: 999999;
}

// date picker

.ant-picker-dropdown.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown {
  z-index: 9999999 !important;
}
