.leaflet-tooltip {
  &.tooltip-building {
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-size: 15px;
    font-family: 'MuseoSans';
    color: #414042;
  }

  &:before {
    display: none;
  }
}

img.leaflet-custom-icon {
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.22);
  width: 100%;
}

.leaflet-control-attribution.leaflet-control {
  background: none;
  padding-right: 10px;
}

// tooltip reset overrides
.leaflet-tooltip {
  border-radius: 6px;
  background: none;
  padding: 0;
  border: none;
  box-shadow: none;
  &:before {
    // hide arrow as it seems to randomly remain even when tooltip is not showing.
    display: none;
  }
  &.tooltip-large {
    z-index: 100;
  }
  &.tooltip-small {
    z-index: 0;
  }
}

.leaflet-touch {
  .leaflet-control-zoom {
    border: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  }
}

.leaflet-top, .leaflet-bottom {
  z-index: 900;
}