body[data-page='map'] {
  header.page-header {
    margin: 35px 0 5px 0;
  }
}
body[data-page='asset-management'] {
  header.page-header {
    margin: 35px 0 70px 0;
  }
}
body[data-page='recent-users'] {
  header.page-header {
    margin: 35px 0 30px 0;
  }
}
