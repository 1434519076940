.u-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.u-no-pointer-event {
  pointer-events: none;
}

.u-no-status-text {
  color: #808285;
  font-style: italic;
}
