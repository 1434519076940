.map-tooltip-animation-enter {
  opacity: 0;
}
.map-tooltip-animation-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.map-tooltip-animation-exit {
  opacity: 1;
}
.map-tooltip-animation-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

// =================
.component-animation-enter {
  opacity: 0;
}
.component-animation-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.component-animation-exit {
  opacity: 1;
}
.component-animation-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
