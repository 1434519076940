body {
  font-size: 15px;
  letter-spacing: normal;
  font-family: 'MuseoSans', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background: #f9f9fb;
  overflow: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #808285;
}
h1 {
  font-size: 38px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 12px;
}

@media (max-width: $mobile-width) {
  h1 {
    font-size: 20px;
  }
  input {
    font-size: 16px !important;
  }
}
a {
  color: #00aeef;
}
a:hover {
  color: #40a9ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;

  &:svg {
    fill: #40a9ff;
  }
}

a:hover button:focus-visible,
[role='button']:focus-visible,
a:focus-visible,
div:focus-visible {
  outline: 1px solid #00aeef;
}

textarea:focus-visible,
input:focus-visible {
  outline: none;
  border: 1px solid #00aeef;
}
